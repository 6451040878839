declare global {
  interface Window {
    Rover: Record<string, any>;
    webkit?: any; // used in a few places: IOSEmbeddedEventStrategy, fireEmbeddedWebEvent, openReferralModal, stripe.client.ts
    rover?: any; // used in a few places: AndroidEmbeddedEventStrategy, fireEmbeddedWebEvent, openReferralModal, stripe.client.ts
  }
}

window.Rover = window.Rover || {};
export default window.Rover;
