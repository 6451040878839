import type jQuery from 'jquery'

declare global {
  interface Window {
    jQuery: typeof jQuery,
    $: typeof jQuery,
  }
}

if (typeof window.jQuery === 'undefined') {
  // eslint-disable-next-line
  const jQuery = require('jquery');

  window.jQuery = jQuery;
  window.$ = jQuery;
}

export type { jQuery }

export default window.jQuery;
